import request from '@/services/axios_instance'
import { mapGetters } from 'vuex'
import Currency from '@/common/Forms/Currency.vue'
import Timezone from '@/common/Forms/Timezone.vue'
export default {
    name: 'General',
    components: {
        Currency,
        Timezone
    },
    data: () => ({
        valid: false,
        paths: [
            { text: 'Settings', disabled: false, route: { name: 'settings' } },
            { text: 'General', disabled: true, route: null }
        ],
        update_btn_loading: false,
        reset_btn_loading: false,
        languages: [{ value: 'english', text: 'English' }],
        themes: [{ value: 'default', text: 'Dashcards Default' }],
        yes_no: [
            { value: false, text: 'No' },
            { value: true, text: 'Yes' }
        ],
        date_formats: [
            { value: 'D-M-Y', text: 'D-M-Y e.g 01-12-2020' },
            { value: 'Y-M-D', text: 'Y-M-D e.g 2020-12-01' },
            { value: 'M-D-Y', text: 'M-D-Y e.g 12-01-2020' }
        ]
    }),
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    created() {
        this.$store.dispatch('configs/fetchSettings', this.user.company.id)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('configs/fetchSettings', this.user.company.id)
        next()
    },
    computed: {
        ...mapGetters(['user']),
        pagination_array() {
            return Array(10)
                .fill()
                .map((_, idx) => {
                    let val = (idx + 1) * 10
                    return { value: val, text: `${val} items per load` }
                })
        },
        language: {
            get() {
                return this.$store.getters['configs/language']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'language',
                    value: val
                })
            }
        },
        theme: {
            get() {
                return this.$store.getters['configs/theme']
            },
            set(val) {
                this.$store.commit('configs/set_setting', { key: 'theme', value: val })
            }
        },
        date_format: {
            get() {
                return this.$store.getters['configs/date_format']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'date_format',
                    value: val
                })
            }
        },
        sender_email: {
            get() {
                return this.$store.getters['configs/sender_email'] ?
                    this.$store.getters['configs/sender_email'] :
                    null
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'sender_email',
                    value: val
                })
            }
        },
        timeline_display_limits: {
            get() {
                return this.$store.getters['configs/timeline_display_limits']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'timeline_display_limits',
                    value: val
                })
            }
        },
        general_page_limits: {
            get() {
                return this.$store.getters['configs/general_page_limits']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'general_page_limits',
                    value: val
                })
            }
        },
        messages_page_limits: {
            get() {
                return this.$store.getters['configs/messages_page_limits']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'messages_page_limits',
                    value: val
                })
            }
        },
        currency: {
            get() {
                return this.$store.getters['configs/currency']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'currency',
                    value: val
                })
            }
        },
        timezone: {
            get() {
                return this.$store.getters['configs/timezone']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'timezone',
                    value: val
                })
            }
        },
        check_user_timer_every: {
            get() {
                return this.$store.getters['configs/check_user_timer_every']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'check_user_timer_enable',
                    value: val
                })
            }
        },
        check_user_timer_enable: {
            get() {
                return this.$store.getters['configs/check_user_timer_enable']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'check_user_timer_enable',
                    value: val
                })
            }
        },
        auto_stop_user_timer_enable: {
            get() {
                return this.$store.getters['configs/auto_stop_user_timer_enable']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'auto_stop_user_timer_enable',
                    value: val
                })
            }
        },
        auto_stop_user_timer_when_reach: {
            get() {
                return this.$store.getters['configs/auto_stop_user_timer_when_reach']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'auto_stop_user_timer_when_reach',
                    value: val
                })
            }
        },
        companyId() {
            return this.user.company.id
        },
        original() {
            return this.$store.getters['configs/original']
        },
        every_hours_selects() {
            return [...Array(24).keys()].map((i) => {
                return {
                    text: `Every ${i + 1} hour${i > 1 ? 's' : ''}`,
                    value: i + 1
                }
            })
        },
        hours_selects() {
            return [...Array(24).keys()].map((i) => {
                return {
                    text: `${i + 1} hour${i > 1 ? 's' : ''}`,
                    value: i + 1
                }
            })
        }
    },
    methods: {
        getPayload() {
            let payload = {
                language: this.$store.getters['configs/language'],
                theme: this.$store.getters['configs/theme'],
                date_format: this.$store.getters['configs/date_format'],
                timeline_display_limits: this.$store.getters['configs/timeline_display_limits'],
                general_page_limits: this.$store.getters['configs/general_page_limits'],
                messages_page_limits: this.$store.getters['configs/messages_page_limits'],
                check_user_timer_every: this.$store.getters['configs/check_user_timer_every'],
                check_user_timer_enable: this.$store.getters['configs/check_user_timer_enable'],
                auto_stop_user_timer_enable: this.$store.getters['configs/auto_stop_user_timer_enable'],
                auto_stop_user_timer_when_reach: this.$store.getters['configs/auto_stop_user_timer_when_reach'],
                currency: this.$store.getters['configs/currency'],
                timezone: this.$store.getters['configs/timezone']
            }
            return payload
        },
        update_settings() {
            if (!this.valid) return
            this.update_btn_loading = true
            this.$store.dispatch('configs/updateSettings', {
                companyId: this.companyId,
                payload: this.getPayload(),
                cb: (data) => {
                    this.update_btn_loading = false
                    this.appSnackbar('Settings updated', 'success')
                }
            })
        }
    }
}