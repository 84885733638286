<template>
  <v-autocomplete
    v-bind="$attrs"
    hide-details="auto"
    :items="currencies_array"
    item-text="text"
    :return-object="returnObject"
    v-model="currency"
    :dense="dense"
    clear-icon="mdi-close-circle-outline"
    :clearable="!required"
    :rules="[required ? requiredRule() : []]"
    outlined
  >
    <template v-slot:item="{ item }">
      <div>
        <v-list-item>
          <v-list-item-icon class="primary--text fw-900">
            {{ item.symbol }}
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 primary--text">
              {{ item.text }} ({{ item.currency_code }})
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { data } from 'currency-codes'
import getSymbolFromCurrency from 'currency-symbol-map'
export default {
  inheritAttrs: false,
  name: 'Currency',
  props: {
    value: {
      type: [Object, String],
      default: () => {
        return { text: 'US Dollar', currency_code: 'USD', symbol: '$' }
      }
    },
    returnObject: { type: Boolean, default: true },
    required: { type: Boolean, default: true },
    dense: { type: Boolean, default: true }
  },
  data: () => ({
    currency: { text: 'US Dollar', currency_code: 'USD', symbol: '$' }
  }),
  computed: {
    currencies_array() {
      return data
        .filter((x) => x.digits > 0 && !['USN'].includes(x.code))
        .map((i) => {
          return {
            currency_code: i.code,
            symbol: getSymbolFromCurrency(i.code),
            text: i.currency
          }
        })
        .sort((a, b) => (a.text > b.text ? 1 : -1))
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.currency = val
      },
      immediate: true,
      deep: true
    },
    currency: {
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style></style>
